import React, { ReactElement } from "react";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";
import SVGUpload from "../../components/svg/Upload";
import "./Page00.scss";
import Import from "../../components/Import/Import";
import { connect } from "react-redux";
import {
    updateForm,
    updateHeaderLogoRightSideVisible
} from "../../store/data/actions";
import Brandings from "../../components/Brandings/Brandings";
import { BrandingTypes } from "../../store/data/types";

interface ComponentProps {
    currentBroker: string;
    updateForm: Function;
    updateHeaderLogoRightSideVisible: Function;
}

interface ComponentState {
    isImportSecondTryVisible: boolean;
    isImportFirstTryVisible: boolean;
    isImportSuccessVisible: boolean;
    isModalBarsLoaderVisible: boolean;
    openFileBrowser: boolean;
}

class Page00 extends React.Component<ComponentProps, ComponentState> {
    fullOverlayRef = React.createRef<HTMLElement>();
    state: ComponentState = {
        isImportSecondTryVisible: false,
        isImportFirstTryVisible: false,
        isImportSuccessVisible: false,
        isModalBarsLoaderVisible: false,
        openFileBrowser: false
    };

    uploadModal: ReactElement = (
        <div className={"modal__button-wrapper"}>
            <Button
                styling={"primary"}
                additionalClass={"button__import-icon"}
                onClick={() =>
                    this.setState({
                        openFileBrowser: true
                    })
                }
            >
                Datei auswählen
                <SVGUpload
                    viewBox={"0 0 14 14"}
                    className={"keyvisual-content__import-icon"}
                />
            </Button>
        </div>
    );

    interruptedUploadModal: ReactElement = (
        <div className={"modal__button-wrapper"}>
            <Button
                styling={"primary"}
                additionalClass={"button__import-icon"}
                onClick={() => {
                    this.setState({
                        openFileBrowser: true
                    });
                }}
            >
                Erneut versuchen
                <SVGUpload
                    viewBox={"0 0 14 14"}
                    className={"keyvisual-content__import-icon"}
                />
            </Button>
        </div>
    );

    importSuccessModal: ReactElement = (
        <div className={"modal__button-wrapper"}>
            <Button
                styling={"primary"}
                onClick={() =>
                    this.setState({
                        isImportSuccessVisible: false
                    })
                }
            >
                Ok, verstanden
            </Button>
        </div>
    );

    goNextStep = (): void => {
        const box = this.fullOverlayRef.current;
        if (box) {
            box.classList.add("full-overlay--disapear");
            // @ts-ignore
            box.nextSibling.classList.add("site--show");
        }

        this.props.updateHeaderLogoRightSideVisible(false);
    };

    loadingModalFirstTry = (obj: ComponentState, fn?: Function): void => {
        this.setState({
            isModalBarsLoaderVisible: true,
            isImportFirstTryVisible: false,
            openFileBrowser: false
        });
        setTimeout(() => {
            this.setState(obj);
            fn && fn();
        }, 2000);
    };

    loadingModalSecondTry = (obj: ComponentState, fn?: Function): void => {
        this.setState({
            isModalBarsLoaderVisible: true,
            isImportSecondTryVisible: false,
            openFileBrowser: false
        });
        setTimeout(() => {
            this.setState(obj);
            fn && fn();
        }, 2000);
    };

    render() {
        if (!this.props.currentBroker) {
            return <p />;
        }

        const currentBrand: BrandingTypes = Brandings(this.props.currentBroker);
        let backgroundImage = currentBrand.backgroundImage;
        let productName = currentBrand.productName;
        let additionalClass = currentBrand.additionalClass;
        let hasExclusiveExtraPdf = currentBrand.hasExclusiveExtraPdf;

        this.props.updateForm({
            pageNumber: 0,
            data: { productName, hasExclusiveExtraPdf }
        });

        return (
            <section
                ref={this.fullOverlayRef}
                className="parallax-layer full-overlay"
            >
                <div className="keyvisual__wrapper">
                    <div className="keyvisual__wrapper--left">
                        <div className={"keyvisual-content__top"}>
                            <div className={"keyvisual-headline"}>
                                {productName + "."}
                            </div>
                            <p className={"keyvisual-subheadline"}>
                                Dokumentieren Sie die kundenindividuelle
                                Vermögensverwaltung einfach und effektiv.
                            </p>
                        </div>

                        <div className={"keyvisual-content__btn-wrapper"}>
                            <Button
                                styling={"primary"}
                                onClick={this.goNextStep}
                            >
                                Anwendung starten
                            </Button>

                            <div
                                className={"keyvisual-content__import"}
                                onClick={() => {
                                    this.setState({
                                        isImportFirstTryVisible: true
                                    });
                                }}
                            >
                                Datensatz importieren
                                <SVGUpload
                                    viewBox={"0 0 14 14"}
                                    className={"keyvisual-content__import-icon"}
                                />
                            </div>
                        </div>
                        <div className="keyvisual-content__footer">
                            <span className="keyvisual-content__footer--text">
                                Deka Vermögensmanagement GmbH
                            </span>
                        </div>
                    </div>
                    <div className="keyvisual__wrapper--right">
                        <div
                            className={
                                "keyvisual" +
                                (additionalClass ? " " + additionalClass : "")
                            }
                            style={{
                                backgroundImage: "url(" + backgroundImage + ")"
                            }}
                        />
                    </div>
                </div>

                <Modal
                    isVisible={this.state.isImportFirstTryVisible}
                    hasCloseX={true}
                    buttonWrapper={this.uploadModal}
                    headlineText={
                        "Sie können einen vorhandenen Datensatz erneut bearbeiten."
                    }
                    onExit={() => {
                        this.setState({ isImportFirstTryVisible: false });
                    }}
                >
                    Importieren Sie die zu Ihrer Kundin bzw. Ihrem Kunden
                    gehörenden Daten.
                    <Import
                        openFileBrowser={this.state.openFileBrowser}
                        onSuccess={() => {
                            this.loadingModalFirstTry(
                                {
                                    isModalBarsLoaderVisible: false,
                                    isImportSuccessVisible: true
                                } as ComponentState,
                                this.goNextStep
                            );
                        }}
                        onError={() => {
                            this.loadingModalFirstTry({
                                isModalBarsLoaderVisible: false,
                                isImportSecondTryVisible: true
                            } as ComponentState);
                        }}
                    />
                </Modal>
                <Modal
                    isVisible={this.state.isImportSecondTryVisible}
                    hasCloseX={true}
                    buttonWrapper={this.interruptedUploadModal}
                    headlineText={"Die Daten konnten nicht importiert werden."}
                    onExit={() => {
                        this.setState({ isImportSecondTryVisible: false });
                    }}
                >
                    <Import
                        openFileBrowser={this.state.openFileBrowser}
                        onSuccess={() => {
                            this.loadingModalSecondTry(
                                {
                                    isModalBarsLoaderVisible: false,
                                    isImportSuccessVisible: true
                                } as ComponentState,
                                this.goNextStep
                            );
                        }}
                        onError={() => {
                            this.loadingModalSecondTry({
                                isModalBarsLoaderVisible: false,
                                isImportSecondTryVisible: true
                            } as ComponentState);
                        }}
                    />
                </Modal>
                <Modal
                    isVisible={this.state.isImportSuccessVisible}
                    hasCloseX={true}
                    buttonWrapper={this.importSuccessModal}
                    headlineText={
                        "Bitte geben Sie die persönlichen Daten erneut ein."
                    }
                    onExit={() => {
                        this.setState({ isImportSuccessVisible: false });
                    }}
                >
                    Aus Datenschutzgründen wird der Datensatz ohne persönliche
                    Daten gespeichert.
                </Modal>
                <Modal
                    isVisible={this.state.isModalBarsLoaderVisible}
                    hasLoader={true}
                    hasCloseX={false}
                    headlineText={"Der Datensatz wird importiert."}
                    onExit={() => {
                        this.setState({ isModalBarsLoaderVisible: false });
                    }}
                />
            </section>
        );
    }
}

const mapStaTeToProps = (props: ComponentProps) => {
    return { currentBroker: props.currentBroker };
};

export default connect(mapStaTeToProps, {
    updateForm,
    updateHeaderLogoRightSideVisible
})(Page00);
